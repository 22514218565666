<template>
  <div class="public-edit">
    <!-- <headers></headers> -->
    <div class="public-edit-wrapper manage-wrapper">
      <!-- <el-breadcrumb separator="/" class="bread-crumb">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/home/manage/public' }">公开课管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑课程</el-breadcrumb-item>
      </el-breadcrumb> -->
      <div class="public-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="编辑课程" name="first">
            <div class="detail-section">
              <el-form ref="form" :model="form" :rules="rules" label-width="180px">

                <el-form-item label="课程名称：" :required="true" prop="name">
                  <el-input v-model="form.name" maxlength="30" show-word-limit style="width:555px"></el-input>
                </el-form-item>

                <el-form-item label="课程简介：" class="textarea">
                  <el-input type="textarea" resize="none" :rows="6" maxlength="300" show-word-limit
                    v-model="form.courseExplain">
                  </el-input>
                </el-form-item>

                <el-form-item label="课程类型：">
                  <el-select v-model="form.courseTypeId" placeholder="请选择课程类型" style="width:555px">
                    <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="课程学科：">
                  <el-select v-model="form.subjectId" placeholder="请选择课程学科" style="width:555px">
                    <el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="年级：">
                  <el-select v-model="form.gradeId" placeholder="年级" style="width:555px">
                    <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="主题封面：">
                  <UploadCover @uploadCover="handleCoverChange" :xcover="form.cover"></UploadCover>
                </el-form-item>

                <el-form-item label="授课老师：" prop="teacher">
                  <el-select v-model="form.teacherId" placeholder="请选择授课老师" filterable>
                    <el-option v-for="item in teacherList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="课程标签：">
                  <el-input v-model="form.tag" maxlength="30" show-word-limit style="width:555px"></el-input>
                </el-form-item>

                <!-- <el-form-item label="教材：" :required="true">
              <el-upload
                class="upload-demo ei_upload"
                drag
                action="https://jsonplaceholder.typicode.com/posts/"
                multiples
              >
                <i class="el-icon-plus"></i>
                <div class="el-upload__text">从教材库选择</div>
              </el-upload>
            </el-form-item> -->

                <el-form-item label="课节附件：">
                  <uploadFile @uploadFile="handleFileChange" :name="'lessonIdentifiers'" :list="form.lessonFileNames" :sourceType=2 />
                </el-form-item>

                <el-form-item label="视频：" :required="true">
                  <uploadFile @uploadFile="handleFileChange" :name="'videoIdentifier'" :list="form.videoName"
                    :xlink="form.link" :tips="{image:false,url:true}" :sourceType=1 />
                </el-form-item>

                <el-form-item label="能力标准：">
                  <el-cascader v-model="form.powerVal" :options="powerList"
                    :props="{ multiple: true, label: 'title', value: 'id' }" @change="changeUsesVal" collapse-tags
                    filterable></el-cascader>
                </el-form-item>

                <el-form-item class="el-form-item-btns">
                  <el-button @click="$router.push('/home/manage/public')">取消</el-button>
                  <el-button type="primary" @click="onSubmit">确定</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeName: "first",
        form: {},
        rules: {
          name: [{
            required: true,
            message: "请输入课程名称",
            trigger: "blur"
          }],
        },
        teacherList: [], //老师列表
        subjectList: [], //课程学科
        typeList: [], //课程类型
        gradeList: [], //年级列表
        powerList: [], //能力标准列表
      };
    },
    methods: {
      //选择能力标准
      changeUsesVal(val) {
        this.form.targetIds = []
        if (!val) return
        // console.log("选择的能力标准", val);
        val.map((item) => {
          this.form.targetIds.push(item[item.length - 1]);
        })
        // console.log(this.form.targetIds);
      },
      handleFileChange(data) {
        // console.log(data);
        this.form[data.name] = data.fileMD5List;
        if (data.name === 'videoIdentifier') this.form.link = data.link;
        // console.log(this.form)
      },
      handleCoverChange(data) {
        // console.log(data)
        this.form.cover = data.md5
      },
      onSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            // 有网址
            if (this.form.link) {
              this.commitForm();
              return
            }
            // 没有网址 没有必填视频
            if (!this.form.videoIdentifier || this.form.videoIdentifier.length == 0) {
              this.$message({
                showClose: true,
                message: '未添加点播视频！',
                type: 'error'
              });
              return;
            }

            this.commitForm();
          }
        })
      },
      async commitForm() {
        // 视频
        if (typeof (this.form.vodIdentifier) !== 'string') {
          this.form.videoIdentifier = this.form.videoIdentifier ? this.form.videoIdentifier[0] : '';
        }
        // 能力标准 
        if (!this.powerVal || this.form.powerVal == this.form.targetIds) {
          this.form.targetIds = [];
          this.form.powerVal.map((item) => {
            this.form.targetIds.push(item[item.length - 1]);
          })
        } else {
          this.targetIds = this.powerVal;
        }
        // console.log(this.form);

        let resData = await this.$Api.Course.editPublicCourse(this.form)
        // console.log(resData)
        if (resData.data.code === 200 && resData.data.msg === "请求成功") {
          this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success"
          });
          setTimeout(() => {
            this.$store.commit('getRefreshState', true);
            this.$router.push('/home/manage/public');
          }, 1000);
        } else {
          this.$notify.error({
            title: "错误",
            message: resData.data.msg
          });
        }
      },
      // 获取公开课课程详情
      async getCourseInfo() {
        let id = this.$route.params.id
        let resData = await this.$Api.Course.getPublicDetail(id);
        // console.log(resData);
        this.form = resData.data

        if (this.form.videoName) {
          let arr = [];
          arr.push(this.form.videoName)
          // this.form.videoIdentifier = this.form.videoName.identifier
          this.form.videoName = arr
        }
        if (this.form.targetIds) {
          Vue.set(this.form, 'powerVal', this.form.targetIds)
        }
        // console.log(this.form);
      },
      //课程类型列表
      async getTypeList() {
        let resData = await this.$Api.Course.TypeList();
        // console.log('课程类型',resData)
        if (!resData.data) return
        this.typeList = resData.data;
      },
      //课程学科列表
      async getSubjectList() {
        let resData = await this.$Api.Course.SubjectList();
        // console.log('学科列表',resData)
        if (!resData.data) return
        this.subjectList = resData.data;
      },
      // 获取年级列表
      async getGradeList() {
        let resData = await this.$Api.Course.getGradeList()
        //  console.log('年级列表',resData)
        if (!resData.data) return
        this.gradeList = resData.data;
      },
      // 获取老师列表
      async getTeacherList() {
        this.teacherlist = [];
        let resData = await this.$Api.Course.getAllTeacher();
        // console.log('老师列表',resData)
        if (!resData.data) return
        this.teacherList = resData.data;
      },
      //获取能力标准
      async getPowerlist() {
        let resData = await this.$Api.Form.getAllPower()
        this.powerList = resData.data;
      },

      // 页面初始化
      init() {
        this.getCourseInfo();
        this.getPowerlist();
        this.getTeacherList();
        this.getSubjectList();
        this.getTypeList();
        this.getGradeList();
      }
    },
    mounted() {
      this.init();
    },
    activated() {
      this.init();
    },
    deactivated() {
      this.form = {};
    },
  };
</script>

<style lang="less" scoped>
  // .public-edit {
  //   .public-edit-wrapper {}

  //   .container {
  //     border-radius: 8px;
  //     background-color: #fff;
  //   }

  //   /deep/ .el-tabs__nav-scroll {
  //     padding: 0 30px;
  //   }

  //   /deep/ .el-input__inner {
  //     height: 34px;
  //     line-height: 34px;
  //     border: 1px solid #ccc;
  //   }

  //   .textarea {
  //     width: 80%;

  //     /deep/ .el-textarea__inner {
  //       resize: none;
  //     }
  //   }
  // }
</style>